<script>
import EnrollmentService from '@/services/EnrollmentService'

export default {
	name: 'ErrorTemplate',
	props: {
		errorObject: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		redirect: async function () {
			if (this.errorObject.reload) {
				await EnrollmentService.reset()
				location.reload()
			}
			await this.$router.push({ name: this.errorObject.redirectPath })
		}
	}
}
</script>
<template>
	<div class="error-template">
		<div class="error-template__title" v-html="errorObject.title"></div>
		<div class="error-template__message" v-html="errorObject.message"></div>
		<base-button class="error-template__button" modifiers="accent font-small small margin-y" @click="redirect()">
			{{ errorObject.action }}
		</base-button>
	</div>
</template>
<style lang="scss" scoped>
.error-template {
	align-items: center;
	border: 1px solid var(--background-variant-light);
	background-color: var(--surface-colour);
	border-radius: var(--border-radius);
	color: var(--on-surface);
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding: 2rem 1rem;
	transition: all 3s ease-in;
	&__title {
		font-weight: var(--font-weight-subheading);
		font-size: var(--font-size-subheading);
		letter-spacing: var(--letter-spacing-expanded);
		margin-bottom: 1rem;
	}
	&__message {
		font-size: var(--font-size-body-1);
		font-weight: var(--font-weight-body-1);
		text-align: center;
		margin-bottom: 0.5rem;
	}
}
</style>
