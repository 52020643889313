<script>
import ErrorTemplate from '@/components/error/ErrorTemplate'
import EnrollmentService from '@/services/EnrollmentService'
import reloadPageMixin from '@/mixins/reload-page-mixin.js'

export default {
	name: 'Error',
	props: {
		sessionTimeOut: {
			type: Boolean,
			default: false
		}
	},
	components: { ErrorTemplate },
	mixins: [reloadPageMixin],
	data: (vm) => ({
		layout: 'public-layout',
		errorObject: {
			title: vm.$t('commonMsg.unexpectedError'),
			message: vm.$t('commonMsg.thereWasErrorCompletingEnrollment'),
			redirectPath: 'intro',
			action: vm.$t('commonMsg.restartEnrollment'),
			reload: true
		},
		showView: false
	}),
	async created () {
		this.enrollmentService = await EnrollmentService.get()
		if (!this.enrollmentService.enrollmentCreated()) {
			await EnrollmentService.reset()
			this.$router.replace({ name: this.errorObject.redirectPath }).catch(() => {})
		}
		if (this.sessionTimeOut) {
			this.errorObject.title = this.$t('commonMsg.sessionTimedOut')
			this.errorObject.message = this.$t('commonMsg.sessionHasExpired')
		}
		this.showView = true
	}
}
</script>
<template>
	<component :is="layout" class="error">
		<error-template :error-object="errorObject" v-if="errorObject && showView" />
	</component>
</template>
<style lang="scss" scoped>
.error {
	transition: all 3s ease-in;
	::v-deep .public-layout__container {
		justify-content: center;
	}
}
</style>
